$color_purple : #5a4cff;
$color_white : #eee;
$color_black : #151515;
$color_dark_grey    : #384047;


@font-face {
    font-family: 'my-font';
    src : url("../assets/font.otf");
}


* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: 'my-font', serif;
}


body {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 8px;
    background: $color_white;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: $color_black;
    border-radius: 20px;
}


