.container-time {
    width: 100%;
    height: 20px;
    background-color: $color_white;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;

    .line-time {
        width: 100%;
        height: 100%;
        background-color: $color_black;
        border-radius: 20px;
        transform: translateX(-100%);
        transition: .25s;
    }

}
