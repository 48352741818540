.page {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    background-color: $color_purple;
}

.container-monitor {
    position: absolute;
    width: 790px;
    height: 150px;
    background-color: #d4e5ff;
    margin: auto;
    top: -40%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(.7);

    .wrapper-monitor {
        margin: auto;
        position: absolute;
        background: #050321;
        width: 770px;
        height: 130px;
        box-shadow: 0 2px 2px 2px rgba(0,0,0,0.30);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .monitor {
            margin: auto;
            position: absolute;
            width: 700px;
            height: 100px;
            background-color: #344151;
            overflow: hidden;
            white-space: nowrap;
            box-shadow: inset 0 5px 10px 2px rgba(0,0,0,0.3);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            p {
                font-family: 'VT323', monospace;
                font-size: 100px;
                position: relative;
                display: inline-block;
                animation: move 20s infinite linear;
                color: $color_purple;
            }
        }

    }

}

@keyframes move {
    from {
    left: 800px;
    }
    to {
      left: -4800px;
    }
}