.container-header {
    width: 100vw;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container-title {
        h1 {
            font-size: 50px;
            color: $color_white;
        }
    }

}
