.button-next {
    margin: 20px auto;
    position: relative;
    width: 200px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    letter-spacing: 2px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.35);
    border-left: 0.5px solid rgba(255, 255, 255, 0.35);
    padding-left: 40px;
    cursor: pointer;
    transition: 0.5s;
    overflow: hidden;


    &:hover {
        padding-left: 0;
        padding-right: 40px;
        color: rgba(255, 255, 255, 1.0);
    }

    span {
        position: absolute;
        left: 5px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $color_purple;
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1a191d;
        font-size: 1.5em;
    }

    &:hover span {
        left: calc(100% - 55px);
    }

    &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 100%;
        z-index: 1;
        background: rgba(255, 255, 255, 0.5);
        transform: translateX(-200px) skewX(30deg);
        transition: 0.5s;
    }

    &:hover::after {
        transform: translateX(200px) skewX(30deg);
    }


}
