.container-form {
    width: 400px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;

    .score {
        color: $color_white;
        margin-bottom: 40px;
        position: relative;
    }

    textarea {
        opacity: 0;
    }

    textarea, .coding-hub{
        background-color: $color_black;
        outline-color: $color_black;
        color: $color_white;
        width: 100%;
        min-height: 100px;
        max-height: 150px;

        .key {
            color : #e00808;
        }

        .function {
            color: #315db0;
        }

        .native {
            color: #c4bb97;
        }

        .nb {
            color: #ab4d09;
        }

        .str {
            color: #1a8645;
        }

    }

    .coding-hub {
        width: 250%;
        padding: 20px;
        border-radius: 20px;
    }

    input {
        color: $color_dark_grey;
        background-color: $color_white;
        box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
        border: none;
        border-radius: 4px;
        padding: 1em;
        margin-bottom: 1.2em;
        width: 100%;
        outline-color: $color_white;
    }

    h2 {
        text-align: center;
        color: $color_white;
        margin-bottom: 20px;
    }

    .title-question-form {
        border-radius: 20px;
        border: 2px solid $color_white;
        padding: 50px 20px;
        width: 200%;
        overflow-x: scroll;
        max-height: 40%;
        box-shadow: 0 0 10px rgba(0, 0, 0, .25), inset 0 0 2px 0 white;
        font-size: 14px;
        font-family: "Bitstream Vera Sans Mono", Monaco, "Courier New", Courier, monospace;
        text-align: left;
        white-space: break-spaces;

        &::-webkit-scrollbar {
            width: 8px;
            background: transparent;
            border-radius: 20px;
            height: 70%;
        }

        &::-webkit-scrollbar-thumb {
            background: $color_white;
            border-radius: 20px;
        }
    }

    .container-response {

        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;
        height: 30%;

        &.choice {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }


        .response-input {
            width: 120px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color_white;
            border-radius: 20px;
            cursor: pointer;
            transition: .5s;


            &:hover {
                transform: scale(1.1);
            }
        }



    }


}


.container-form.anim {
    animation: 2s launch-container;
}

@keyframes launch-container {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
